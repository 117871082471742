/* istanbul ignore file: code unreachable, should be removed when UseNewExpressCheckoutButtonsInProductPage FT is merged */
import {DynamicPaymentMethodsShape} from '@wix/wixstores-client-storefront-sdk/dist/es/src/enums/productPageSettings.enums';
import {APP_DEFINITION_ID, PageMap, ServerTransactionStatus} from '@wix/wixstores-client-core/dist/es/src/constants';
import {WidgetProps} from '@wix/cashier-express-checkout-widget/dist/src/types/WidgetProps';
import {BreakdownTypes, PaymentBreakdown} from '@wix/cashier-express-checkout-widget/dist/src/types/PaymentBreakdown';
import {IOptionSelectionVariant} from '@wix/wixstores-client-core/dist/es/src/types/product';
import {PaymentAuthorizedArgs} from '@wix/cashier-express-checkout-widget/dist/src/types/ExternalContract';
import {cashierExpressAddressToEcomAddress} from '@wix/wixstores-client-storefront-sdk/dist/es/src/utils/cart/cashierExpressAddressToEcomAddress/cashierExpressAddressToEcomAddress';
import {CheckoutApi} from '@wix/wixstores-client-storefront-sdk/dist/es/src/apis/CheckoutApi/CheckoutApi';
import {ShippingMethod} from '@wix/cashier-express-checkout-widget/src/types/Shipping';
import {OrderItem} from '@wix/cashier-express-checkout-widget/dist/src/types/OrderItem';
import {translate} from '@wix/wixstores-client-core/dist/es/src/utils/Translate';
import {
  ShippingContactRestricted,
  ShippingContactSelectedUpdate,
  ShippingError,
} from '@wix/cashier-express-checkout-widget/dist/src/types/Shipping';
import {IPlaceOrderParams} from '@wix/wixstores-client-storefront-sdk/dist/es/src/apis/types/executor.types';
import {PlaceOrderResponse} from '@wix/wixstores-client-storefront-sdk/dist/es/src/apis/CartApi/types';
import {CheckoutForFastFlow} from '@wix/wixstores-client-storefront-sdk/dist/es/src/apis/CheckoutApi/types';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {ProductService} from './ProductService';
import {get, round} from 'lodash';
import {clickOnCheckoutWithEWallet} from '@wix/bi-logger-ec-site/v2';
import {StoresWidgetID} from '@wix/wixstores-client-core';
import {graphqlOperation, ProductType} from '../constants';
import {VolatileCartService} from './VolatileCartService';
import {CheckoutType, DirectPurchaseService} from './DirectPurchaseService';
import {gqlQuery, gqlStoreFrontQuery} from './getProduct';
import {query as getCountryCodes} from '../../graphql/getCountryCodes.graphql';
import {query as getCheckoutSettings} from '../../graphql/getCheckoutSettings.graphql';
import {GetCheckoutSettingsQuery, GetCountryCodesQuery} from '../../graphql/queries-schema';
import {IProductDTO, UserInput, VolatileCartFromServer} from '../types/app-types';

export interface PaymentBreakdownAndAmount {
  paymentBreakdown: PaymentBreakdown;
  paymentAmount: string;
}

export class CashierExpressService {
  private cashierExpressPaymentOrderId: string;
  private cashierExpressWidgetShippingRuleId: string;
  private countryCodes: GetCountryCodesQuery['localeData']['countries'];
  private checkoutSettings: GetCheckoutSettingsQuery['checkoutSettings'];
  private canSelectShippingMethod: boolean;

  constructor(
    private readonly siteStore: SiteStore,
    private readonly checkoutApi: CheckoutApi,
    private readonly volatileCartService: VolatileCartService
  ) {
    //
  }

  public getInitialProps(
    product: IProductDTO,
    dynamicPaymentMethodsButtonShape: DynamicPaymentMethodsShape,
    checkoutCurrencies: string[]
  ): Pick<WidgetProps, 'meta' | 'requestShipping' | 'currency' | 'locale' | 'buttonStyle' | 'domain' | 'demoMode'> {
    return {
      requestShipping: product.productType !== ProductType.DIGITAL,
      buttonStyle: {
        shape: dynamicPaymentMethodsButtonShape === DynamicPaymentMethodsShape.pill ? 'pill' : 'rect',
        height: 42,
      },
      domain: this.siteStore.location.baseUrl,
      meta: {
        appDefId: APP_DEFINITION_ID,
        appInstanceId: this.siteStore.storeId,
        siteId: this.siteStore.msid,
        visitorId: this.siteStore.uuid as string,
        productId: product.id,
        appInstance: this.siteStore.instanceManager.getInstance(),
        siteOwnerId: this.siteStore.ownerId,
        consumerWidgetId: StoresWidgetID.PRODUCT_PAGE,
      },
      currency: this.getCurrencyForCashierProps(checkoutCurrencies),
      locale: this.siteStore.locale,
      demoMode: this.siteStore.isEditorMode(),
    };
  }

  private getCurrencyForCashierProps(checkoutCurrencies: string[]) {
    return checkoutCurrencies?.includes(this.siteStore.getCurrentCurrency())
      ? this.siteStore.getCurrentCurrency()
      : this.siteStore.currency;
  }

  private getProductNameWithUserInputs(product: IProductDTO, userInputs: UserInput) {
    const options = userInputs.selection
      .map((selection, index) => `${product.options[index].title}: ${selection?.value}`)
      .join(', ');
    const freeTexts = userInputs.text
      .map((text, index) => `${product.customTextFields[index].title}: ${text}`)
      .join(', ');
    return `${product.name}${options ? ', ' : ''}${options}${freeTexts ? ', ' : ''}${freeTexts}`;
  }

  private readonly getCashierCartItems = (
    product: IProductDTO,
    itemsTotal: number,
    userInputs: UserInput,
    additionalFees: VolatileCartFromServer['cart']['additionalFees']
  ): OrderItem[] => {
    const quantity = userInputs.quantity[0];
    const price = round(itemsTotal / quantity, 2);
    return [
      {id: product.id, name: this.getProductNameWithUserInputs(product, userInputs), price: price.toString(), quantity},
      ...(additionalFees || []).map((additionalFee) => ({
        id: additionalFee.code,
        name: additionalFee.name,
        price: additionalFee.totalPrice.toString(),
        quantity: 1,
      })),
    ];
  };

  public getPaymentBreakdown(
    product: IProductDTO,
    selectedVariant: IOptionSelectionVariant,
    userInputs: UserInput
  ): Pick<WidgetProps, 'paymentAmount' | 'paymentBreakdown' | 'orderItems'> {
    const quantity = ProductService.getQuantity(userInputs);
    const actualProduct = selectedVariant || product;
    const calculatedPrice = round((actualProduct.comparePrice || actualProduct.price) * quantity, 2);
    const {totals, additionalFees} = this.volatileCartService;
    const additionalFeesTotalCost = totals?.additionalFeesTotal || 0;
    const subtotalCost = totals?.subtotal || 0;
    const itemsTotalWithAdditionalFees = round(subtotalCost + additionalFeesTotalCost, 2);
    const itemsTotal = totals ? itemsTotalWithAdditionalFees : calculatedPrice;
    const paymentBreakdown = {
      [BreakdownTypes.ItemsTotal]: itemsTotal.toString(),
      [BreakdownTypes.Shipping]: (totals?.shipping || 0).toString(),
      [BreakdownTypes.Tax]: (totals?.tax || 0).toString(),
      [BreakdownTypes.Discount]: (totals?.discount || 0).toString(),
    };

    const orderItems = this.getCashierCartItems(
      product,
      totals ? totals.subtotal || 0 : calculatedPrice,
      userInputs,
      additionalFees
    );

    return {
      paymentAmount: (totals ? totals.total || 0 : calculatedPrice).toString(),
      paymentBreakdown,
      orderItems,
    };
  }

  public readonly getEstimatedPrice = (
    product: IProductDTO,
    selectedVariant: IOptionSelectionVariant,
    userInputs: UserInput
  ): string => {
    const quantity = ProductService.getQuantity(userInputs);
    const actualProduct = selectedVariant || product;
    const calculatedPrice = round((actualProduct.comparePrice || actualProduct.price) * quantity, 2);

    return `${calculatedPrice}`;
  };

  public async handleCashierPaymentSubmit(
    paymentInfo: PaymentAuthorizedArgs,
    accessibilityEnabled: boolean,
    product: IProductDTO
  ): Promise<'success' | 'fail' | 'shouldNavigateToCheckout'> {
    const shouldRequestShipping = product.productType !== ProductType.DIGITAL;
    if (shouldRequestShipping) {
      await this.setCartShippingAddressAndDestination(paymentInfo);
    }
    await this.setCartBillingAddress(paymentInfo);

    const customFieldMandatory = this.isCustomFieldMandatory();
    const termsAndConditionsDisabled = !this.checkoutSettings.termsAndConditions.enabled;

    const cart = await this.volatileCartService.getCart();
    const notEnoughInfoAboutSubdivision = cart.cartService.cart.destinationCompleteness.includes('SUBDIVISION');
    const onlyOneShippingMethod = cart.cartService.cart.shippingRuleInfo?.shippingRule?.options.length === 1;

    const noMissingShippingData =
      !notEnoughInfoAboutSubdivision &&
      ((cart.cartService.cart.shippingRuleInfo?.canShipToDestination &&
        (this.canSelectShippingMethod || onlyOneShippingMethod)) ||
        !shouldRequestShipping);

    const canPayWithoutNavigatingToCheckout =
      termsAndConditionsDisabled && noMissingShippingData && !customFieldMandatory;
    if (canPayWithoutNavigatingToCheckout) {
      const placeOrderResponse = await this.placeOrder(paymentInfo);
      const wasPlaceOrderSuccessful = placeOrderResponse.cartStatus.success;
      const isFailedTransaction =
        placeOrderResponse.paymentResponse.transactionStatus === ServerTransactionStatus.Failed;

      if (!wasPlaceOrderSuccessful || isFailedTransaction) {
        return 'fail';
      }

      this.cashierExpressPaymentOrderId = placeOrderResponse.orderId;

      return 'success';
    } else {
      return 'shouldNavigateToCheckout';
    }
  }

  public async onCashierExpressPaymentSuccess() {
    await this.siteStore.navigate(
      {
        sectionId: PageMap.THANKYOU,
        queryParams: {objectType: 'order', origin: 'cashier-express-directly-to-typ'},
        state: this.cashierExpressPaymentOrderId,
      },
      true
    );
  }

  private isCustomFieldMandatory() {
    return this.checkoutSettings?.checkoutCustomField?.mandatory && this.checkoutSettings?.checkoutCustomField?.show;
  }

  private fetchCountryCodes() {
    return gqlStoreFrontQuery(this.siteStore, getCountryCodes, {}, graphqlOperation.GetCountryCodes);
  }

  private fetchCheckoutSettings() {
    return gqlQuery(this.siteStore, getCheckoutSettings, {}, graphqlOperation.GetCheckoutSettings);
  }

  public async fetchInitialDataAndCreateVolatileCart(
    product: IProductDTO,
    userInputs: UserInput,
    isPreOrderState: boolean
  ) {
    const [checkoutSettings, countryCodes] = await Promise.all([
      this.fetchCheckoutSettings(),
      this.fetchCountryCodes(),
      this.volatileCartService.getStandaloneCheckoutIds(product, userInputs, CheckoutType.Cashier, isPreOrderState),
    ]);

    this.countryCodes = countryCodes.data.localeData.countries;
    this.checkoutSettings = checkoutSettings.data.checkoutSettings;
  }

  public logBiClickOnCheckoutWithEWallet(product: IProductDTO) {
    const biEvenProps = {
      origin: 'product page',
      productsList: product.id,
      cartId: this.volatileCartService.cartId,
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.siteStore.webBiLogger.report(
      clickOnCheckoutWithEWallet({
        ...biEvenProps,
        catalogAppId: '215238eb-22a5-4c36-9e7b-e7c08025e04e',
        checkoutId: this.volatileCartService.checkoutId,
      })
    );
  }

  public async createCheckout(product: IProductDTO, userInputs: UserInput, isPreOrderState: boolean): Promise<string> {
    await this.fetchInitialDataAndCreateVolatileCart(product, userInputs, isPreOrderState);

    return this.volatileCartService.checkoutId;
  }

  public handleExpressCashierOnClick(product: IProductDTO, directPurchaseService: DirectPurchaseService): void {
    this.canSelectShippingMethod = false;
    directPurchaseService.handleExpressCashierOnClick(product);
  }

  public async handleCashierOnClick(
    product: IProductDTO,
    directPurchaseService: DirectPurchaseService,
    userInputs: UserInput,
    isPreOrderState: boolean
  ): Promise<boolean> {
    this.canSelectShippingMethod = false;
    const canCheckout = await directPurchaseService.handleCashierOnClick(product);
    if (canCheckout) {
      await this.fetchInitialDataAndCreateVolatileCart(product, userInputs, isPreOrderState);
      this.logBiClickOnCheckoutWithEWallet(product);
    }

    return canCheckout;
  }

  public async onShippingMethodSelected(
    shippingMethod: ShippingMethod
  ): Promise<Pick<WidgetProps, 'paymentAmount' | 'paymentBreakdown'>> {
    await this.setShippingOption(shippingMethod);
    this.canSelectShippingMethod = true;

    const cart = await this.volatileCartService.getCart();
    const {paymentAmount, paymentBreakdown} = this.getPaymentBreakdownAndAmount(cart.cartService.cart);

    return {paymentAmount, paymentBreakdown};
  }

  public async fetchPaymentBreakdownForCashierAddress(
    shippingAddress: ShippingContactRestricted,
    translations
  ): Promise<ShippingContactSelectedUpdate> {
    const {country, subdivision, zipCode} = cashierExpressAddressToEcomAddress(shippingAddress, {}, this.countryCodes);
    const res = await this.setShippingOptionForFastFlow(country, subdivision, zipCode);
    const cart = res.cartService.cart;
    const notEnoughInfoAboutSubdivision = cart.destinationCompleteness.includes('SUBDIVISION');
    const {paymentAmount, paymentBreakdown} = this.getPaymentBreakdownAndAmount(cart);
    const shippingMethods = this.getShippingMethods(cart, translations);

    /* istanbul ignore else: todo(eran): test else */
    if (cart.shippingRuleInfo.canShipToDestination || notEnoughInfoAboutSubdivision) {
      return {
        paymentAmount,
        paymentBreakdown,
        shippingMethods,
      };
    } else {
      return {error: ShippingError.SHIPPING_ADDRESS_UNSERVICEABLE};
    }
  }

  private getShippingMethods(cart, translations): ShippingMethod[] {
    this.cashierExpressWidgetShippingRuleId = get(cart, 'shippingRuleInfo.shippingRule.id', null);
    let shippingRuleOptions = get(cart, 'shippingRuleInfo.shippingRule.options', []);

    if (cart.selectedShippingOption) {
      const selectedShippingOption = shippingRuleOptions.find((option) => option.id === cart.selectedShippingOption.id);

      const filteredShippingOptions = shippingRuleOptions.filter(
        (option) => option.id !== cart.selectedShippingOption.id
      );

      shippingRuleOptions = [selectedShippingOption, ...filteredShippingOptions];
    }

    return shippingRuleOptions.map((shippingMethod) => {
      return {
        label: shippingMethod.title,
        amount: shippingMethod.rate.toString(),
        identifier: shippingMethod.id,
        detail:
          (shippingMethod.pickupInfo
            ? translate(translations['productPage.shippingOption.pickup.addressFormatSubdivision'], {
                addressLine: get(shippingMethod, 'pickupInfo.address.addressLine', null),
                city: get(shippingMethod, 'pickupInfo.address.city', null),
                subdivision: get(shippingMethod, 'pickupInfo.address.subdivisionName', null),
                country: get(shippingMethod, 'pickupInfo.address.countryName', null),
                zipCode: get(shippingMethod, 'pickupInfo.address.zipCode', null),
              })
            : shippingMethod.deliveryTime) || '',
      };
    });
  }

  private getPaymentBreakdownAndAmount(cart): PaymentBreakdownAndAmount {
    const totals = cart.totals;
    const paymentAmount = totals.total.toString();
    const additionalFeesTotalCost = (totals.additionalFeesTotal as number) || 0;
    const itemsTotalWithAdditionalFees = round((totals.itemsTotal as number) + additionalFeesTotalCost, 2);
    const paymentBreakdown: PaymentBreakdown = {
      [BreakdownTypes.Shipping]: totals.shipping.toString(),
      [BreakdownTypes.Tax]: this.checkoutSettings.taxOnProduct ? '0' : totals.tax.toString(),
      [BreakdownTypes.Discount]: totals.discount.toString(),
      [BreakdownTypes.ItemsTotal]: itemsTotalWithAdditionalFees.toString(),
    };

    return {paymentAmount, paymentBreakdown};
  }

  private async setShippingOptionForFastFlow(
    country: string,
    subdivision: string,
    zipCode: string
  ): Promise<CheckoutForFastFlow> {
    const {cartId, checkoutId} = this.volatileCartService;
    const address = {
      country,
      subdivision,
      zipCode,
    };
    return this.checkoutApi.setCartShippingAddressesForFastFlowEstimation(cartId, address, checkoutId);
  }

  private placeOrder(paymentInfo: PaymentAuthorizedArgs): Promise<PlaceOrderResponse> {
    const params: IPlaceOrderParams = {
      cartId: this.volatileCartService.cartId,
      paymentId: paymentInfo.detailsId,
      shouldRedirect: true,
      isPickupFlow: false,
      inUserDomain: true,
      forceLocale: this.siteStore.locale,
      deviceType: this.siteStore.isMobile() ? 'mobile' : 'desktop',
    } as IPlaceOrderParams;

    if (this.volatileCartService.checkoutId) {
      params.checkoutId = this.volatileCartService.checkoutId;
    }
    return this.checkoutApi.placeOrder(params);
  }

  private async setCartBillingAddress(paymentInfo: PaymentAuthorizedArgs): Promise<void> {
    const billingAddress = cashierExpressAddressToEcomAddress({}, paymentInfo.billingContact, this.countryCodes);
    const {cartId, checkoutId} = this.volatileCartService;

    await this.checkoutApi.setCartBillingAddress(cartId, billingAddress, checkoutId);
  }

  private async setCartShippingAddressAndDestination(paymentInfo: PaymentAuthorizedArgs): Promise<void> {
    const {cartId, checkoutId} = this.volatileCartService;
    const address = cashierExpressAddressToEcomAddress(
      paymentInfo.shippingContact,
      paymentInfo.billingContact,
      this.countryCodes
    );

    await this.checkoutApi.setCartShippingAddressAndDestination(cartId, address, checkoutId);
  }

  private async setShippingOption(shippingMethod: ShippingMethod): Promise<void> {
    const {cartId, checkoutId} = this.volatileCartService;
    const selectedShippingOption = {
      shippingRuleId: this.cashierExpressWidgetShippingRuleId,
      optionId: shippingMethod.identifier,
    };

    await this.checkoutApi.setShippingOption({cartId, selectedShippingOption, checkoutId});
  }
}
